import api from '../api';

export function getAllTownHalls({ townHall = '' } = '') {
  return api.request({
    method: 'GET',
    url: `/prefeitura?Cidade=${townHall}`,
  });
}

export function getTownHallById({ id = '' }){
  return api.request({
    method: 'GET',
    url: `/prefeitura/${id}`,
  });
}
