import api from '../api';

export async function getVehicles({
  usuarioId,
  placa,
  modelo,
  marca,
  tipoVeiculo,
  size=5,
  page=1
}) {
  return api.request({
    method: 'GET',
    url: `/veiculo?UsuarioId=${usuarioId}
      &Marca=${marca}&Modelo=${modelo}&Placa=${placa}&TipoVeiculo=${tipoVeiculo}
      &PageSize=${size}&Page=${page}`,
  });
}

export const getVehicleActive = ({ userId = '', page}) =>
  api.request({
    method: "GET",
    url: `/veiculo?usuarioId=${userId}&Page=${page}&PageSize=5&CadAtivo=true`,
  });

export function addVehicle({
  usuarioId,
  formatoPlaca,
  placa,
  tipoVeiculo,
  marca,
  modelo,
}) {
  return api.request({
    method: 'POST',
    url: '/veiculo',
    data: {
      usuarioId,
      formatoPlaca,
      placa,
      tipoVeiculo,
      marca,
      modelo,
    },
  });
}

export const deleteVehicle = ({ vehicleId }) =>
  api.request({
    url: `/veiculo/${vehicleId}`,
    method: 'DELETE',
  });
