export const Types = {
  GET_VEHICLE_LIST: 'townHall/GET_VEHICLE_LIST',
  GET_VEHICLE_LIST_SUCCESS: 'townHall/GET_VEHICLE_LIST_SUCCESS',
  GET_VEHICLE_LIST_ERROR: 'townHall/GET_VEHICLE_LIST_ERROR',

  ADD_VEHICLE: 'townHall/ADD_VEHICLE',
  ADD_VEHICLE_SUCCESS: 'townHall/ADD_VEHICLE_SUCCESS',
  ADD_VEHICLE_ERROR: 'townHall/ADD_VEHICLE_ERROR',

  DELETE_VEHICLE: 'townHall/DELETE_VEHICLE',
  DELETE_VECHICLE_SUCESS: 'townHall/DELETE_VEHICLE_SUCESS',
  DELETE_VEHICLE_ERROR: 'townHall/DELETE_VEHICLE_ERROR',
};

const INIT_STATE = {
  vehicleList: [],
  loading: false,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_VEHICLE_LIST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_VEHICLE_LIST_SUCCESS: {
      const { vehicleList } = action.payload;
      return {
        ...state,
        vehicleList,
        loading: false,
      };
    }

    case Types.GET_VEHICLE_LIST_ERROR:
      return {
        ...state,
        vehicleList: [],
        loading: false,
      };

    case Types.ADD_VEHICLE:
      return {
        ...state,
        loading: true,
      };

    case Types.ADD_VEHICLE_SUCCESS: {
      const { vehicle } = action.payload;

      return {
        ...state,
        loading: false,
        vehicleList: state.vehicleList.concat(vehicle),
      };
    }

    case Types.ADD_VEHICLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case Types.DELETE_VEHICLE:
      return {
        ...state,
        loading: false,
      };

    case Types.DELETE_VECHICLE_SUCESS:
      const { vehicleId } = action.payload;

      const newArray = state.vehicleList.filter(
        (vehicle) => vehicle.id !== vehicleId
      );
      return {
        ...state,
        vehicleList: newArray,
        loading: false,
      };

    case Types.DELETE_VEHICLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export function getVehicleList(filters) {
  return {
    type: Types.GET_VEHICLE_LIST,
    payload: {
      ...filters,
    },
  };
}

export function addVehicle(vehicle) {
  return {
    type: Types.ADD_VEHICLE,
    payload: {
      vehicle,
    },
  };
}

export function deleteVehicle(vehicleId) {
  return {
    type: Types.DELETE_VEHICLE,
    payload: {
      vehicleId,
    },
  };
}
