import { combineReducers } from 'redux';

import authUser from './auth/reducer';
import card from './card/reducer';
import menu from './menu/reducer';
import settings from './settings/reducer';
import townHall from './townHall/reducer';
import user from './user/reducer';
import vehicle from './vehicle/reducer';
import wallet from './wallet/reducer';

const reducers = combineReducers({
  menu,
  card,
  settings,
  authUser,
  wallet,
  user,
  townHall,
  vehicle,
});

export default reducers;
