export const Types = {
  GET_USER_DATA: 'user/GET_USER_DATA',
  GET_USER_DATA_SUCCESS: 'user/GET_USER_DATA_SUCCESS',
  GET_USER_DATA_ERROR: 'user/GET_USER_DATA_ERROR',
};

const INIT_STATE = {
  id: null,
  documentNumber: null,
  email: '',
  name: '',
  loading: false,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_USER_DATA:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_USER_DATA_SUCCESS: {
      const { id, documentNumber, email, name } = action.payload;

      return {
        ...state,
        id,
        documentNumber,
        email,
        name,
        loading: false,
      };
    }

    default:
      return state;
  }
}

export const getUserData = () =>{
  return{
    type: Types.GET_USER_DATA
  }
}
