import { call, put } from 'redux-saga/effects';

import { login } from '../../services/requests/Auth';

import { Types as authTypes } from './reducer';
import { Types as userTypes } from '../user/reducer';

import { Alert } from '../../helpers/Alert';
import formatError from '../../helpers/Errors';

export function* loginAsync({ payload }) {
  try {
    const response = yield call(login, {
      ...payload.userLoginData,
    });

    localStorage.setItem('token', response.data?.access_token);
    localStorage.setItem('refreshToken', response.data?.['.refreshToken']);
    localStorage.setItem('userId', response.data?.['.id']);

    yield put({
      type: userTypes.GET_USER_DATA_SUCCESS,
      payload: {
        id: response.data?.['.id'],
        documentNumber: response.data?.['.documentNumber'],
        name: response.data?.['.name'],
        email: response.data?.['.email'],
      },
    });

    yield put({
      type: authTypes.LOGIN_USER_SUCCESS,
      payload: {
        accessToken: response.data?.access_token,
      },
    });

    payload.history.push('/');
  } catch (error) {
    yield put({
      type: authTypes.LOGIN_USER_ERROR,
    });
    console.log(error);
    Alert({
      type: 'error',
      title: formatError(error),
    });
  }
}

export function* logoutAsync() {
  try {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');

    yield put({
      type: authTypes.LOGOUT_USER_SUCCESS,
    });
  } catch (error) {
    console.log(error);
    Alert({
      type: 'error',
      title: 'Erro ao deslogar',
    });
  }
}
