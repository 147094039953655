export const Types = {
  GET_WALLET: 'wallet/GET_WALLET',
  GET_WALLET_SUCCESS: 'wallet/GET_WALLET_SUCCESS',
  GET_WALLET_ERROR: 'wallet/GET_WALLET_ERROR',

  SET_WALLET_TYPE: 'wallet/SET_WALLET_TYPE',

  CHANGE_WALLET: 'wallet/CHANGE_WALLET',
};

const INIT_STATE = {
  balance: 0,
  townHallId: null,
  loading: false,
  walletType: null,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_WALLET:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_WALLET_SUCCESS:
      return {
        ...state,
        balance: action.payload.balance,
        townHallId: action.payload.townHallId,
        walletType: action.payload.walletType,
        loading: false,
      };

    case Types.GET_WALLET_ERROR:
      return {
        ...state,
        loading: false,
      };

    case Types.SET_WALLET_TYPE:
      return {
        ...state,
        walletType: action.payload.walletType,
        loading: false,
      };

    default:
      return state;
  }
}

export function getWallet() {
  return {
    type: Types.GET_WALLET,
  };
}

export function changeWallet(townHallId) {
  return {
    type: Types.CHANGE_WALLET,
    payload: {
      townHallId,
    },
  };
}
