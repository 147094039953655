import { call, put } from 'redux-saga/effects';

import { Alert } from '../../helpers/Alert';
import formatError from '../../helpers/Errors';
import {
  getVehicles,
  deleteVehicle,
  addVehicle as addVehicleRequest,
} from '../../services/requests/Vehicles';
import { Types } from './reducer';

export function* getAllVehiclesAsync({ payload }) {
  try {
    const usuarioId = Number(localStorage.getItem('userId'));
    const response = yield call(getVehicles, { ...payload, usuarioId });

    yield put({
      type: Types.GET_VEHICLE_LIST_SUCCESS,
      payload: {
        vehicleList: response.data.data,
      },
    });
  } catch (error) {
    yield put({
      type: Types.GET_VEHICLE_LIST_ERROR,
    });
    Alert({
      type: 'error',
      title: formatError(error),
    });
  }
}

export function* addVehicle({ payload }) {
  try {
    const request = {
      ...payload.vehicle,
    };

    const response = yield call(addVehicleRequest, request);

    yield put({
      type: Types.ADD_VEHICLE_SUCCESS,
      payload: {
        vehicle: {
          ...response.data.data,
        },
      },
    });
  } catch (error) {
    console.log(error);
    Alert({
      type: 'error',
      title: formatError(error),
    });
    yield put({
      type: Types.ADD_VEHICLE_ERROR,
    });
  }
}

export function* deleteVehicleAsync({ payload }) {
  try {
    yield call(deleteVehicle, payload);

    yield put({
      type: Types.DELETE_VECHICLE_SUCESS,
      payload: {
        vehicleId: payload.vehicleId,
      },
    });
  } catch (error) {
    Alert({
      type: 'error',
      title: formatError(error),
    });
    yield put({
      type: Types.DELETE_VEHICLE_ERROR,
    });
  }
}
