import { call, put, select } from 'redux-saga/effects';

import { getWallet } from '../../services/requests/Wallet';
import { getTownHallById } from '../../services/requests/TownHall';

import { Types as walletTypes } from './reducer';
import { Types as townHallTypes } from '../townHall/reducer';

import { Alert } from '../../helpers/Alert';
import formatError from '../../helpers/Errors';
import getLocation from '../../helpers/GetLocation';

export function* getWalletAsync({ payload }) {
  try {
    const townHallId = Number(localStorage.getItem('townHallId'));
    const userId = Number(localStorage.getItem('userId'));

    let response;
    if (townHallId) {
      response = yield call(getWallet, {
        userId,
        townHallId,
      });
    } else {
      const position = yield getLocation();

      if (!position.permission) {
        throw 'Erro ao recuperar sua localização atual.';
      }

      response = yield call(getWallet, {
        userId,
        coords: position.coords,
      });

      localStorage.setItem('townHallId', response.data?.data?.prefeituraId);
    }

    yield put({
      type: walletTypes.GET_WALLET_SUCCESS,
      payload: {
        townHallId: response.data?.data?.prefeituraId,
        balance: response.data?.data?.saldo,
        walletType: response.data?.data?.tipoCarteira,
      },
    });

    yield put({
      type: townHallTypes.SET_SELECTED_TOWN_HALL_SUCCESS,
      payload: {
        townHallId: response.data?.data?.prefeituraId,
      },
    });
  } catch (error) {
    if (formatError(error) === 'CarteiraUsuario não foi encontrado.') {

      try {
        const response = yield call(getTownHallById, {
          id: Number(localStorage.getItem('townHallId')),
        });
        yield put({
          type: walletTypes.GET_WALLET_SUCCESS,
          payload: {
            townHallId: Number(localStorage.getItem('townHallId')),
            balance: 0,
            walletType: response.data?.data?.tipoCarteira,
          },
        });
        yield put({
          type: townHallTypes.SET_SELECTED_TOWN_HALL_SUCCESS,
          payload: {
            townHallId: Number(localStorage.getItem('townHallId')),
          },
        });
      } catch (error){

        yield put({
          type: walletTypes.GET_WALLET_ERROR,
        });
        console.log(error);
        Alert({
          type: 'error',
          title: formatError(error),
        });
      }
    } else {
      yield put({
        type: walletTypes.GET_WALLET_ERROR,
      });
      console.log(error);
      Alert({
        type: 'error',
        title: formatError(error),
      });
    }
  }
}

export function* changeWallet({ payload }) {
  try {
    const { townHallId } = payload;
    const userId = Number(localStorage.getItem('userId'));
    const response = yield call(getWallet, {
      userId,
      townHallId,
    }); // dont return if client not have wallet in townhall

    yield put({
      type: walletTypes.GET_WALLET_SUCCESS,
      payload: {
        townHallId: response.data?.data?.prefeituraId,
        balance: response.data?.data?.saldo,
        walletType: response.data?.data?.tipoCarteira,
      },
    });

    yield put({
      type: townHallTypes.SET_SELECTED_TOWN_HALL_SUCCESS,
      payload: {
        townHallId,
      },
    });

    localStorage.setItem('townHallId', townHallId);
  } catch (error) {
    if (formatError(error) === 'CarteiraUsuario não foi encontrado.') {

      try {
        const response = yield call(getTownHallById, {
          id: payload.townHallId,
        });
        yield put({
          type: walletTypes.GET_WALLET_SUCCESS,
          payload: {
            townHallId: payload.townHallId,
            balance: 0,
            walletType: response.data?.data?.tipoCarteira,
          },
        });
        yield put({
          type: townHallTypes.SET_SELECTED_TOWN_HALL_SUCCESS,
          payload: {
            townHallId: payload.townHallId,
          },
        });
        localStorage.setItem('townHallId', payload.townHallId);
      } catch (error) {

        yield put({
          type: walletTypes.GET_WALLET_ERROR,
        });
        Alert({
          type: 'error',
          title: formatError(error),
        });
      }

    } else {
      yield put({
        type: walletTypes.GET_WALLET_ERROR,
      });
      Alert({
        type: 'error',
        title: formatError(error),
      });
    }
  }
}
