import { call, put } from 'redux-saga/effects';

import { Alert } from '../../helpers/Alert';
import formatError from '../../helpers/Errors';
import getLocation from '../../helpers/GetLocation';
import {
  getAllTownHalls,
  getTownHallById,
} from '../../services/requests/TownHall';
import { getWallet } from '../../services/requests/Wallet';
import { Types as walletTypes } from '../wallet/reducer';
import { Types as townHallTypes } from './reducer';

export function* getAllTownHallsAsync() {
  try {
    const response = yield call(getAllTownHalls);

    const townHallId = Number(localStorage.getItem('townHallId'));

    yield put({
      type: townHallTypes.GET_TOWN_HALLS_LIST_SUCCESS,
      payload: {
        list: response.data.data,
        storedTownHallId: townHallId || null,
      },
    });
  } catch (error) {
    yield put({
      type: townHallTypes.GET_TOWN_HALLS_LIST_ERROR,
    });
    Alert({
      type: 'error',
      title: formatError(error),
    });
  }
}

export function* searchTownHallsAsync({ payload }) {
  try {
    const response = yield call(getAllTownHalls, {
      townHall: payload.townHallName,
    });

    yield put({
      type: townHallTypes.SEARCH_TOWN_HALL_SUCCESS,
      payload: {
        list: response.data.data,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

export function* getTownHallAndWalletAsync() {
  try {
    const townHallId = Number(localStorage.getItem('townHallId'));
    const userId = Number(localStorage.getItem('userId'));
    let isWalletFailed = false;

    let responseWallet;
    try {
      if (townHallId) {
        responseWallet = yield call(getWallet, {
          userId,
          townHallId,
        });
      } else {
        const position = yield getLocation();

        if (!position.permission) {
          Alert({
            type: 'error',
            title: 'Erro ao recuperar sua localização atual.',
          });
          isWalletFailed = true;
        } else {
          responseWallet = yield call(getWallet, {
            userId,
            coords: position.coords,
          });

          localStorage.setItem(
            'townHallId',
            responseWallet.data?.data?.prefeituraId
          );
        }
      }

      yield put({
        type: walletTypes.SET_WALLET_TYPE,
        payload: {
          walletType: responseWallet.data?.data?.tipoCarteira,
        },
      });
    } catch (error) {
      isWalletFailed = true;
      Alert({
        type: 'error',
        title: formatError(error),
      });
    }

    const responseTownHallList = yield call(getAllTownHalls);

    if (!isWalletFailed) {
      try {
        const response = yield call(getTownHallById, {
          id: responseWallet.data?.data?.prefeituraId,
        });

        yield put({
          type: walletTypes.GET_WALLET_SUCCESS,
          payload: {
            townHallId: responseWallet.data?.data?.prefeituraId,
            balance: responseWallet.data?.data?.saldo,
            walletType: response.data?.data?.tipoCarteira,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }

    yield put({
      type: townHallTypes.GET_TOWN_HALLS_LIST_SUCCESS,
      payload: {
        list: responseTownHallList.data.data,
        storedTownHallId: isWalletFailed
          ? null
          : responseWallet.data?.data?.prefeituraId || null,
      },
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: townHallTypes.GET_TOWN_HALLS_LIST_ERROR,
    });
    Alert({
      type: 'error',
      title: formatError(error),
    });
  }
}
