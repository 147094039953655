import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Alert react instance
const swal = withReactContent(Swal);

// eslint-disable-next-line import/prefer-default-export
export const Alert = ({
  title,
  type,
  confirmButtonText,
  showConfirmButton,
  timer,
  text,
}) => {
  switch (type) {
    case 'success': {
      return swal.fire({
        icon: 'success',
        title: title || 'Sucesso',
        showConfirmButton: showConfirmButton || false,
        timer: timer || 1500,
      });
    }
    case 'error': {
      return swal.fire({
        icon: 'error',
        title: title || 'Erro',
        showConfirmButton: showConfirmButton || false,
        timer: timer || 3500,
      });
    }

    case 'warning': {
      return swal.fire({
        icon: 'warning',
        title: title || 'Atenção',
        showConfirmButton: showConfirmButton || false,
        timer: timer || 3500,
      });
    }

    // Alerta para modais de confirmar ou deletar, ao clicar em confirmar ele retorna uma promise com um value=true. Para se utilizar, deve chamar este alert passando o type="confirm" e .then(result => if (result.value) {função}) para ativar o botão de confirmar.
    case 'confirm': {
      return swal.fire({
        title: title || 'Você tem certeza?',
        text: text || 'Não será possível reverter esta ação.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#201d46',
        cancelButtonColor: '#dc3545',
        confirmButtonText: confirmButtonText || 'Confirmar',
      });
    }
    default:
  }
};
