import api from '../api';

export const getCreditCards = (userId) =>
  api.request({
    url: `/cartaocreditousuario?UsuarioId=${userId}`,
    method: 'GET',
  });

export const addCreditCard = ({
  usuarioId,
  numero,
  bandeiraCartao,
  nomeImpresso,
  cvv,
  mesVencimento,
  anoVencimento,
}) =>
  api.request({
    url: '/cartaocreditousuario',
    method: 'POST',
    data: {
      usuarioId,
      numero,
      bandeiraCartao,
      nomeImpresso,
      cvv,
      mesVencimento,
      anoVencimento,
    },
  });

export const deleteCreditCard = ({ cardId }) =>
  api.request({
    url: `/cartaocreditousuario/${cardId}`,
    method: 'DELETE',
  });
