import { all, takeLatest, throttle } from 'redux-saga/effects';

import { Types as authTypes } from './auth/reducer';
import * as auth from './auth/saga';
import { Types as cardTypes } from './card/reducer';
import * as card from './card/saga';
import { Types as townHallTypes } from './townHall/reducer';
import * as townHall from './townHall/saga';
import { Types as vehicleTypes } from './vehicle/reducer';
import * as vehicle from './vehicle/saga';
import { Types as walletTypes } from './wallet/reducer';
import * as wallet from './wallet/saga';

function* watchAuthAsync() {
  yield takeLatest(authTypes.LOGIN_USER, auth.loginAsync);
  yield takeLatest(authTypes.LOGOUT_USER, auth.logoutAsync);
}

function* watchWalletAsync() {
  yield takeLatest(walletTypes.GET_WALLET, wallet.getWalletAsync);
  yield takeLatest(walletTypes.CHANGE_WALLET, wallet.changeWallet);
}

function* watchVehicleAsync() {
  yield throttle(
    1000,
    vehicleTypes.GET_VEHICLE_LIST,
    vehicle.getAllVehiclesAsync
  );
  yield takeLatest(vehicleTypes.DELETE_VEHICLE, vehicle.deleteVehicleAsync);
  yield takeLatest(vehicleTypes.ADD_VEHICLE, vehicle.addVehicle);
}

function* watchTownHallAsync() {
  yield takeLatest(
    townHallTypes.GET_TOWN_HALLS_LIST,
    townHall.getAllTownHallsAsync
  );
  yield throttle(
    1000,
    townHallTypes.SEARCH_TOWN_HALL,
    townHall.searchTownHallsAsync
  );
  yield takeLatest(
    townHallTypes.GET_TOWN_HALLS_AND_WALLET,
    townHall.getTownHallAndWalletAsync
  );
}

function* watchCardAsync() {
  yield takeLatest(cardTypes.GET_CARDS, card.getCards);
  yield takeLatest(cardTypes.ADD_CARD, card.addCard);
  yield takeLatest(cardTypes.DELETE_CARD, card.deleteCard);
}

export default function* rootSaga(getState) {
  yield all([
    watchAuthAsync(),
    watchWalletAsync(),
    watchTownHallAsync(),
    watchVehicleAsync(),
    watchCardAsync(),
  ]);
}
