export const Types = {
  GET_TOWN_HALLS_LIST: 'townHall/GET_TOWN_HALLS_LIST',
  GET_TOWN_HALLS_LIST_SUCCESS: 'townHall/GET_TOWN_HALLS_LIST_SUCCESS',
  GET_TOWN_HALLS_LIST_ERROR: 'townHall/GET_TOWN_HALLS_LIST_ERROR',

  SET_SELECTED_TOWN_HALL_SUCCESS: 'townHall/SET_SELECTED_TOWN_HALL_SUCCESS',

  SEARCH_TOWN_HALL: 'townHall/SEARCH_TOWN_HALL',
  SEARCH_TOWN_HALL_SUCCESS: 'townHall/SEARCH_TOWN_HALL_SUCCESS',

  GET_TOWN_HALLS_AND_WALLET: 'townHall/GET_TOWN_HALLS_AND_WALLET',
};

const INIT_STATE = {
  townHallsList: [],
  selectedTownHall: null,
  loading: false,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_TOWN_HALLS_LIST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_TOWN_HALLS_LIST_SUCCESS: {
      const { storedTownHallId } = action.payload;

      const selectedTownHall = action.payload.list.find(
        (townHall) => townHall.id === storedTownHallId
      );

      return {
        ...state,
        townHallsList: action.payload.list,
        selectedTownHall: selectedTownHall || null,
        loading: false,
      };
    }

    case Types.GET_TOWN_HALLS_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    case Types.SET_SELECTED_TOWN_HALL_SUCCESS: {
      const { townHallId } = action.payload;

      const selectedTownHall = state.townHallsList.find(
        (townHall) => townHall.id === townHallId
      );
      return {
        ...state,
        selectedTownHall: selectedTownHall || null,
        loading: false,
      };
    }

    case Types.SEARCH_TOWN_HALL_SUCCESS: {
      return {
        ...state,
        townHallsList: action.payload?.list,
      };
    }

    case Types.GET_TOWN_HALLS_AND_WALLET: {
      return {
        ...state,
        loading: true,
      };
    }

    default:
      return state;
  }
}

export function getTownHallsList() {
  return {
    type: Types.GET_TOWN_HALLS_LIST,
  };
}

export function searchTownHall(townHallName) {
  return {
    type: Types.SEARCH_TOWN_HALL,
    payload: {
      townHallName,
    },
  };
}

export function getTownHallAndWallet() {
  return {
    type: Types.GET_TOWN_HALLS_AND_WALLET,
  };
}
